document.addEventListener('DOMContentLoaded', function(){
    var direction = document.documentElement.getAttribute("dir");
    if (direction == "rtl") {
        wrap_not_hebrew_words();
    }
});

function wrap_not_hebrew_words(element)
{
    if (typeof element === "undefined") element = document.body;
    findAndReplaceDOMText(element, {
        preset: "prose",
        find: /[^\u0000-\u003F\u0590-\u05FF]+/g,
        wrap: 'span',
        wrapClass: 'latin'
    });
}